"use client";
import Logo from "../../assets/images/footer-img1.png";
import { Container } from "react-bootstrap";
import "./styles.scss";

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <div className="footer-box1">
          <div className="">
            <div className="footer-txt1">
              For support please email{" "}
              <a href="mailto:speakers@adfw.com" style={{ color: "#fff" }}>
                speakers@adfw.com
              </a>
            </div>
          </div>
          <div className="">
            <div className="footer-txt2">
              Copyright ©️ 2024 ADFW. All Rights Reserved
            </div>
          </div>
          <div className="">
            <img src={Logo} alt="logo" width={100} />
          </div>
        </div>
      </Container>
    </div>
  );
};
export default Footer;
