import { Button, Modal } from "antd";
import { Col, Row } from "react-bootstrap";

const PrivacyModal = (props: any) => {
  return (
    <Modal
      open={props?.open}
      onOk={() => props?.close()}
      onCancel={() => props?.close()}
      centered
      width={800}
      footer={false}
    >
      <br />
      <div>
        <h5>Learn more about the use of your personal data</h5>
        <div>
          By submitting your passport copies and personal data to ADGM (Abu
          Dhabi Global Market), you consent to the processing of this
          information for the specific purposes of applying for speaker permits
          and facilitating all related processes for participation in the
          upcoming Abu Dhabi Finance Week (ADFW). ADGM assures that your
          personal data will be handled in accordance with applicable data
          protection laws.
          <br />
          <ul>
            <li>
              <strong>Data Usage and Confidentiality: </strong> ADGM will solely
              utilize your passport copies and personal data to fulfill the
              aforementioned purposes. This data will not be disclosed to any
              third parties without your explicit consent, except where required
              by law or necessary for the completion of permit-related
              processes.
            </li>

            <li>
              <strong>Data Retention:</strong> Upon successfully obtaining all
              necessary permits for your participation in ADFW, ADGM will
              promptly erase your passport copies and any other personal data
              collected, unless retention is required by law or for legitimate
              business purposes.
            </li>
            <li>
              <strong>Data Safety: </strong>ADGM implements appropriate
              technical and organizational measures to protect your personal
              data against unauthorized access, accidental loss, or alteration.
            </li>

            <li>
              <strong>Contact: </strong>For any inquiries regarding the
              processing of your personal data or to exercise your rights under
              data protection laws, please contact&nbsp;
              <a href="mailto:dpo@adgm.com">dpo@adgm.com</a>.
            </li>
          </ul>
        </div>
      </div>
      <br />
      <Row>
        <Col md="10"></Col>
        <Col md="2">
          <Button
            type="primary"
            onClick={() => props?.close()}
            style={{ width: "100%" }}
          >
            Close
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default PrivacyModal;
