import API from "../config/apis";

const POST = async (url: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Accept-Language", "en-US,en;q=0.9");
      myHeaders.append("Connection", "keep-alive");
      myHeaders.append("Content-Type", "application/json");
      const requestOptions: any = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(body),
        redirect: "follow",
      };
      const response = await fetch(`${API.BASE_URL}${url}`, requestOptions);
      const result = await response.json();
      if (response.ok) {
        resolve(result);
      } else {
        reject(result);
      }
    } catch (error) {
      reject(error);
    }
  });
};

// const FILE_UPLOAD = async (file: any) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       // Supported file types
//       const supportedFileTypes = [
//         "application/pdf",
//         "image/jpeg",
//         "image/jpg",
//         "image/png",
//       ];

//       // Check if the file type is supported
//       if (!supportedFileTypes.includes(file.type)) {
//         let err: any = {
//           status: false,
//           data: "",
//           message:
//             "Unsupported file type. Please upload a PDF, JPG, JPEG, or PNG file.",
//         };
//         resolve(err);
//       }
//       const myHeaders = new Headers();
//       const formdata = new FormData();
//       formdata.append("files", file, file.name);
//       const raw: any = {
//         method: "POST",
//         headers: myHeaders,
//         body: formdata,
//         redirect: "follow",
//       };

//       const response = await fetch(`${API.BASE_URL}${API.UPLOAD}`, raw);
//       const result: any = await response.json();

//       if (result?.status) {
//         resolve(result);
//       } else {
//         let err: any = {
//           status: false,
//           data: "",
//           message: "File upload failed",
//         };
//         reject(new Error(err));
//       }
//     } catch (error) {
//       let err: any = {
//         status: false,
//         data: "",
//         message: "File upload failed",
//       };
//       reject(new Error(err));
//     }
//   });
// };

const FILE_UPLOAD = async (file: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      // Supported file types
      const supportedFileTypes = [
        "application/pdf",
        "image/jpeg",
        "image/jpg",
        "image/png",
      ];

      // Check if the file type is supported
      if (!supportedFileTypes.includes(file.type)) {
        reject({
          status: false,
          data: "",
          message:
            "Unsupported file type. Please upload a PDF, JPG, JPEG, or PNG file.",
        });
        return;
      }

      const myHeaders = new Headers();
      const formdata = new FormData();
      formdata.append("files", file, file.name);

      const response = await fetch(`${API.BASE_URL}${API.UPLOAD}`, {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      });

      const result = await response.json();

      if (result?.status) {
        resolve(result);
      } else {
        // reject({
        //   status: false,
        //   data: "",
        //   message: "File upload failed",
        // });
        throw new Error("File upload failed");
      }
    } catch (error) {
      // reject({
      //   status: false,
      //   data: "",
      //   message: "File upload failed",
      // });
      throw new Error("File upload failed");
    }
  });
};

export { POST, FILE_UPLOAD };
