import { Button, Modal } from "antd";
import { IoIosCheckmarkCircle } from "react-icons/io";
import "../styles.scss";

const SuccessModal = (props: any) => {
  return (
    <div>
      <Modal
        onOk={() => props?.close()}
        onCancel={() => props?.close()}
        footer={false}
        centered
        open={props?.open}
      >
        <div className="success-modal-box1">
          <IoIosCheckmarkCircle color="#002646" size={100} />
          <br />
          <div className="success-modal-txt1">
            Thank you for your cooperation.
          </div>
          <div className="success-modal-txt2">
            Our speaker onboarding team will contact you to share further
            information and facilitate your participation ahead of ADFW 2024.
          </div>
          <br />
          <div className="success-modal-txt2">
            Download the official ADFW media kit{" "}
            <a target="_blank" href="https://bit.ly/adfw-speaker-kit">
              here
            </a>
            , which can be used to amplify your presence at ADFW 2024 across
            social channels.
          </div>
          <br />
          <Button
            onClick={() => props?.close()}
            size="large"
            className="success-modal-btn"
            type="primary"
            key="console"
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default SuccessModal;
