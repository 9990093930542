import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import HomeScreen from "./app/homeScreen";
import "bootstrap/dist/css/bootstrap.min.css";
import { ConfigProvider } from "antd";
import ThemeObj from "./theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConfigProvider theme={ThemeObj}>
      <HomeScreen />
    </ConfigProvider>
  </React.StrictMode>
);

reportWebVitals();
