const API = {
  // BASE_URL: "https://api-dev.adfw.com/api/",

  BASE_URL: "https://api-prod.adfw.com/api/",

  // SPEAKERS: "speakers",

  SPEAKERS: "speakers/createSpeaker",

  UPLOAD: "uploadImageorPdf",
};

export default API;
