import React from "react";
const HomeIntro = () => {
  return (
    <div>
      <div className="home-text2">
        <strong>
          Welcome to the Abu Dhabi Finance Week [ADFW] 2024 speaker onboarding
          platform.
        </strong>
      </div>
      <br />
      <div className="home-text2">
        We are sincerely grateful for your participation in this year's edition
        and look forward to facilitating a productive journey in Abu Dhabi.
      </div>
      <br />
      <div className="home-text2" style={{ marginBottom: 6 }}>
        To ensure a seamless and secure onboarding experience, and for
        communication of your participation, relevant documents and details are
        kindly requested. Submitting your information is for:
      </div>
      <ul>
        <li>
          Non-confidential details, such as your name, biography, photo, and
          other publicly accessible information, which will be used to only
          support the promotion of your participation in the event on ADFW-owned
          channels such as the official website, email communications, social
          media channels, on-ground event branding, agenda, and more.
        </li>
        <li>
          Personal documents, which will be accessed only by the designated team
          and used for the specific purpose of applying for speaker permits - a
          mandatory requirement for public-speaking events in the United Arab
          Emirates.
        </li>
      </ul>
      <div className="home-text2">
        Please, fill in this form within 3 business days.
      </div>
    </div>
  );
};

export default HomeIntro;
