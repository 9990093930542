import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  message,
  Select,
  Tooltip,
} from "antd";
import { PhoneNumberUtil } from "google-libphonenumber";
import { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ImagePicker from "../../components/imagePicker";
import { POST } from "../../utils/apiCalls";
import Country from "../../utils/countryCodes.json";
import Years from "./components/years.json";

import { CiCircleInfo } from "react-icons/ci";
import API from "../../config/apis";
import PrivacyModal from "./components/privacy-modal";
import SuccessModal from "./components/success-modal";

const Step2 = (props: any) => {
  const [form] = Form.useForm();
  const phoneInputRef = useRef<HTMLDivElement>(null);
  const { Option } = Select;
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [isEmirates, setIsEmirates] = useState(false);
  const [countryCode, setCountryCode] = useState("+971");
  const [uploading, setUploading] = useState(false);

  const [photo_url, setPhoto_Url] = useState<any>();
  const [passport_url, setPassportUrl] = useState<any>();
  const [EmiIDFront_url, setEmiIDFrontUrl] = useState<any>();
  const [EmiIDBack_url, setEmiIDBackUrl] = useState<any>();

  const [wordCounts, setWordCounts] = useState({
    bio: 0,
    socialDescription: 0,
  }); // Add other fields as needed

  const handleWordCount = (fieldName: any) => (e: any) => {
    const text = e.target.value.trim();
    const words = text.split(/\s+/).filter((word: any) => word.length > 0);
    setWordCounts((prevCounts) => ({
      ...prevCounts,
      [fieldName]: words.length,
    }));
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      if (!phoneNumber) {
        throw new Error("Phone number is missing.");
      }
      const parsedNumber = phoneUtil.parse(phoneNumber);
      const isValid = phoneUtil.isValidNumber(parsedNumber);
      if (isValid) {
        return { status: true };
      } else {
        return { status: false };
      }
    } catch (error) {
      console.error("Error parsing phone number:", error);
      return { status: false };
    }
  };

  const onFinish = async (values: any) => {
    try {
      const isValid = validatePhoneNumber(
        `${values?.countryCode}${values?.phone}`
      );
      if (!isValid.status) {
        if (phoneInputRef.current) {
          phoneInputRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        form.setFields([
          {
            name: "phone",
            errors: ["Invalid Phone Number"],
          },
        ]);

        return;
      }

      setIsLoading(true);
      let obj = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        countryCode: `${values?.countryCode}`,
        phone: `${values?.phone}`,
        designation: values?.designation,
        companyName: values?.companyName,
        bio: values?.bio,
        nationality: values?.nationality,
        residentCountry: values?.residentCountry,
        emirate: "",
        qualification: values?.qualification,
        graduationYear: values?.graduationYear,
        uniName: values?.uniName,
        social: values?.social,
        socialDescription: values?.socialDescription,
        photoUrl: photo_url,
        passportUrl: passport_url,
        emiratesIdFront: EmiIDFront_url,
        emiratesIdBack: EmiIDBack_url,
      };
      // let raw = {
      //   data: obj,
      // };
      const speaker: any = await POST(API.SPEAKERS, obj);
      if (speaker?.data?.id) {
        setSuccess(true);
      } else {
        message.error("Speaker not created. Please try again");
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      message.error("Speaker not created. Something went wrong");
      setIsLoading(false);
    }
  };

  const clearForm = () => {
    setIsLoading(false);
    form.resetFields();
    setSuccess(false);
    setPhoto_Url(null);
    setPassportUrl(null);
    setEmiIDFrontUrl(null);
    setEmiIDBackUrl(null);
  };

  const EmirateChage = (value: any, allValues: any) => {
    setCountryCode(allValues?.countryCode);
    if ("residentCountry" in value) {
      console.log(value);
      if (value?.residentCountry === "UAE") {
        setIsEmirates(true);
      } else {
        setIsEmirates(false);
      }
    }
  };

  const PrefixSelector = () => {
    return (
      <Form.Item
        noStyle
        name="countryCode"
        rules={[{ required: true, message: "Please select country code" }]}
      >
        <Select
          style={{ width: 85 }}
          bordered={false}
          showSearch={true}
          className="home-select2"
          size="large"
        >
          {Country.map((item: any, index: number) => (
            <Select.Option key={index} value={item.dial_code}>
              {item.dial_code}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  return (
    <Card
      className="home-card"
      title={<div className="home-text3">Speaker Info</div>}
    >
      <Form
        onFinish={onFinish}
        form={form}
        onValuesChange={EmirateChage}
        initialValues={{ countryCode: "+971" }}
      >
        <Row>
          <Col sm={6} xs={12}>
            <Form.Item
              name={"firstName"}
              rules={[
                {
                  required: true,
                  message: "Please enter your first name",
                },
              ]}
              style={{ marginBottom: 40 }}
            >
              <Input
                bordered={false}
                placeholder="First Name *"
                size="large"
                className="home-input"
              />
            </Form.Item>
          </Col>
          <Col sm={6} xs={12}>
            <Form.Item
              name={"lastName"}
              rules={[
                {
                  required: true,
                  message: "Please enter your last name",
                },
              ]}
              style={{ marginBottom: 40 }}
            >
              <Input
                bordered={false}
                placeholder="Last Name *"
                size="large"
                className="home-input"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col sm={6} xs={12}>
            <Form.Item
              name={"email"}
              rules={[
                {
                  required: true,
                  message: "Please enter your email",
                },
                {
                  type: "email",
                  message: "Please enter a valid Email",
                },
              ]}
              style={{ marginBottom: 40 }}
            >
              <Input
                bordered={false}
                placeholder="Email ID *"
                size="large"
                className="home-input"
              />
            </Form.Item>
          </Col>
          <Col sm={6} xs={12} ref={phoneInputRef}>
            <Form.Item
              name={"phone"}
              rules={[
                {
                  required: true,
                  message: "Please enter your contact number / POC number",
                },

                {
                  validator: (_, value) => {
                    const isValid = validatePhoneNumber(
                      `${countryCode}${value}`
                    );
                    if (!value) {
                      return Promise.reject();
                    }
                    if (!isValid.status) {
                      return Promise.reject(new Error("Invalid Phone Number"));
                    }

                    return Promise.resolve();
                  },
                },
              ]}
              style={{ marginBottom: 40, marginTop: -7.5 }}
            >
              <Input
                addonBefore={<PrefixSelector />}
                type="number"
                bordered={false}
                placeholder="Contact Number / POC Contact *"
                size="large"
                className="home-input"
                style={{ paddingBottom: 5 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col sm={6} xs={12}>
            <Form.Item
              name={"designation"}
              rules={[
                {
                  required: true,
                  message: "Please enter your designation",
                },
              ]}
              style={{ marginBottom: 40 }}
            >
              <Input
                bordered={false}
                placeholder="Designation *"
                size="large"
                className="home-input"
              />
            </Form.Item>
          </Col>
          <Col sm={6} xs={12}>
            <Form.Item
              name={"companyName"}
              rules={[
                {
                  required: true,
                  message: "Please enter your company",
                },
              ]}
              style={{ marginBottom: 40 }}
            >
              <Input
                bordered={false}
                placeholder="Company Name *"
                size="large"
                className="home-input"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name={"bio"}
          rules={[
            {
              validator: (_, value) => {
                const wordCount = wordCounts.bio;
                if (!wordCount) {
                  return Promise.reject(new Error("Please enter your bio"));
                }
                if (wordCount < 50) {
                  return Promise.reject(new Error("Minimum 50 words required"));
                }
                if (wordCount > 75) {
                  return Promise.reject(new Error("Maximum 75 words allowed"));
                }
                return Promise.resolve();
              },
            },
          ]}
          style={{ marginBottom: 40 }}
        >
          <Input.TextArea
            rows={4}
            bordered={false}
            size="large"
            placeholder="Short Bio *"
            className="home-input"
            onChange={handleWordCount("bio")}
          />
        </Form.Item>
        <div style={{ textAlign: "right", marginTop: -30, marginBottom: 30 }}>
          <p>{wordCounts.bio} / 75 Words (Min 50 words : Max 75 words)</p>
        </div>
        <Row>
          <Col sm={6} xs={12}>
            <Form.Item
              name={"nationality"}
              rules={[
                {
                  required: true,
                  message: "Please enter your nationality",
                },
              ]}
              style={{ marginBottom: 50 }}
            >
              <Select
                bordered={false}
                placeholder="Nationality *"
                size="large"
                className="home-select"
                allowClear
                showSearch
              >
                {Country?.map((item: any) => {
                  return (
                    <Select.Option key={item.name} value={item.name}>
                      {item?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={6} xs={12}>
            <Form.Item
              name={"residentCountry"}
              rules={[
                {
                  required: true,
                  message: "Please enter your residency",
                },
              ]}
              style={{ marginBottom: 40 }}
            >
              <Select
                bordered={false}
                placeholder="Residency *"
                size="large"
                className="home-select"
                allowClear
                showSearch
              >
                <Option key={"UAE"} value="UAE">
                  UAE
                </Option>
                <Option key={"Non-UAE"} value="Non-UAE">
                  Non-UAE
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* {isEmirates ? (
          <Row>
            <Col sm={6} xs={12}>
              <Form.Item
                name={"emirate"}
                rules={[
                  {
                    required: true,
                    message: "Please select your emirate",
                  },
                ]}
                style={{ marginBottom: 40 }}
              >
                <Select
                  bordered={false}
                  placeholder="Emirate *"
                  size="large"
                  className="home-select"
                  allowClear
                  showSearch
                >
                  {Emirates?.map((item: any) => {
                    return (
                      <Select.Option key={item?.id} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={6} xs={12}></Col>
          </Row>
        ) : null} */}

        <Row>
          <Col sm={6} xs={12}>
            <Form.Item
              name={"qualification"}
              rules={[
                {
                  required: true,
                  message: "Please enter your highest qualification",
                },
              ]}
              style={{ marginBottom: 40 }}
            >
              <Input
                bordered={false}
                placeholder="Highest Qualification *"
                size="large"
                className="home-input"
              />
            </Form.Item>
          </Col>

          <Col sm={6} xs={12}>
            <div style={{ margin: 10 }} />
            <Form.Item
              name={"graduationYear"}
              rules={[
                {
                  required: true,
                  message: "Please enter your graduation year",
                },
              ]}
              style={{ marginBottom: 40 }}
            >
              <Select
                bordered={false}
                placeholder="Year of Graduation *"
                size="large"
                className="home-select"
                allowClear
                showSearch
              >
                {Years?.map((item: any) => {
                  return (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col sm={6} xs={12}>
            <Form.Item
              name={"uniName"}
              rules={[
                {
                  required: true,
                  message: "Please enter your institute name",
                },
              ]}
              style={{ marginBottom: 30 }}
            >
              <Input
                bordered={false}
                placeholder="Institute Name *"
                size="large"
                className="home-input"
              />
            </Form.Item>
          </Col>
          <Col sm={6} xs={12}></Col>
        </Row>
        <Row>
          <Col sm={6} xs={12}>
            <Form.Item
              name={"photo_file"}
              rules={[
                {
                  required: true,
                  message: "Please upload your photo",
                },
              ]}
            >
              <ImagePicker
                tooltip
                label={"Photo"}
                formats={["image/png", "image/jpeg", "image/jpg"]}
                fileURL={photo_url}
                size={3145728}
                onLoading={(status: any) => setUploading(status)}
                onChange={(value: any) => {
                  setPhoto_Url(value?.url);
                  form.setFieldValue("photo_file", value?.url);
                }}
              />
              <div className="home-label">
                <div>Min resolution: 500*500px (JPEG, JPG, PNG)</div>
                <div className="home-label2">
                  <div>Max size: 3 MB</div>
                  <Tooltip title="Please upload a high-resolution headshot photo with a white background, showing your face clearly.">
                    <CiCircleInfo
                      color="#002646"
                      size={18}
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </div>
              </div>
            </Form.Item>
          </Col>
          <Col sm={6} xs={12}>
            <Form.Item
              name={"passport_file"}
              rules={[
                {
                  required: true,
                  message: "Please upload your passport copy",
                },
              ]}
            >
              <ImagePicker
                label={"Passport Copy"}
                formats={[
                  "image/png",
                  "image/jpeg",
                  "image/jpg",
                  "application/pdf",
                ]}
                fileURL={passport_url}
                size={3145728}
                onLoading={(status: any) => setUploading(status)}
                onChange={(value: any) => {
                  setPassportUrl(value?.url);
                  form.setFieldValue("passport_file", value?.url);
                }}
              />
              <div className="home-label">
                <div>Colored scan of passport (JPEG, PNG, PDF)</div>
                <div>Max size: 3 MB</div>
              </div>
            </Form.Item>
          </Col>
          {isEmirates ? (
            <>
              <Col sm={6} xs={12}>
                <Form.Item
                  name={"EmiIDFront_file"}
                  rules={[
                    {
                      required: true,
                      message: "Please upload the front of your emirates ID.",
                    },
                  ]}
                >
                  <ImagePicker
                    label={"Emirates ID - Front "}
                    formats={[
                      "image/png",
                      "image/jpeg",
                      "image/jpg",
                      "application/pdf",
                    ]}
                    fileURL={EmiIDFront_url}
                    size={3145728}
                    onLoading={(status: any) => setUploading(status)}
                    onChange={(value: any) => {
                      setEmiIDFrontUrl(value?.url);
                      form.setFieldValue("EmiIDFront_file", value?.url);
                    }}
                  />
                  <div className="home-label">
                    <div>Allowed file types: JPEG, PNG, PDF</div>
                    <div>Max size: 3 MB</div>
                  </div>
                </Form.Item>
              </Col>
              <Col sm={6} xs={12}>
                <Form.Item
                  name={"EmiIDBack_file"}
                  rules={[
                    {
                      required: true,
                      message: "Please upload the back of your emirates ID.",
                    },
                  ]}
                >
                  <ImagePicker
                    label={"Emirates ID - Back "}
                    formats={[
                      "image/png",
                      "image/jpeg",
                      "image/jpg",
                      "application/pdf",
                    ]}
                    fileURL={EmiIDBack_url}
                    size={3145728}
                    onLoading={(status: any) => setUploading(status)}
                    onChange={(value: any) => {
                      setEmiIDBackUrl(value?.url);
                      form.setFieldValue("EmiIDBack_file", value?.url);
                    }}
                  />
                  <div className="home-label">
                    <div>Allowed file types: JPEG, PNG, PDF</div>
                    <div>Max size: 3 MB</div>
                  </div>
                </Form.Item>
              </Col>
            </>
          ) : null}
        </Row>
        <br />
        <div className="home-text2">
          <strong>
            Would you like to submit a short quote to be published on our social
            media channels to announce your participation?
          </strong>
        </div>
        <br />
        <Form.Item
          name={"socialDescription"}
          rules={[
            {
              validator: (_, value) => {
                const wordCount = wordCounts.socialDescription;
                if (wordCount > 25) {
                  return Promise.reject(new Error("Maximum 25 words allowed"));
                }
                return Promise.resolve();
              },
            },
          ]}
          style={{ marginBottom: 40 }}
        >
          <Input.TextArea
            rows={2}
            bordered={false}
            size="large"
            placeholder="Short Quote"
            className="home-input"
            onChange={handleWordCount("socialDescription")}
          />
        </Form.Item>
        <div
          style={{
            textAlign: "right",
            marginTop: "-38px",
          }}
        >
          <p>{wordCounts.socialDescription} / 25 Words ( Max 25 words)</p>
        </div>
        <Form.Item
          name={"agree"}
          rules={[
            {
              required: true,
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("You must agree to the terms and conditions")
                    ),
            },
          ]}
          valuePropName="checked"
        >
          <div>
            <Checkbox></Checkbox>
            &nbsp; *I have read and agree to the{" "}
            <a className="home-text" onClick={() => setPrivacy(!privacy)}>
              Privacy Policy
            </a>{" "}
            and consent to the processing of my data
          </div>
        </Form.Item>
        <br />
        <Button
          disabled={uploading ? true : false}
          block
          type="primary"
          size="large"
          htmlType="submit"
          style={{
            height: 50,
            fontWeight: "bold",
          }}
          loading={isLoading}
        >
          SUBMIT
        </Button>
      </Form>
      {success ? (
        <SuccessModal open={success} close={() => clearForm()} />
      ) : null}
      {privacy ? (
        <PrivacyModal open={privacy} close={() => setPrivacy(!privacy)} />
      ) : null}
    </Card>
  );
};

export default Step2;
