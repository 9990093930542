import "./styles.scss";
import { useState } from "react";
import moment from "moment";
import Dropzone from "react-dropzone";
import { RiImageAddLine } from "react-icons/ri";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { message, Spin, Tooltip } from "antd";
import { BiSolidFilePdf } from "react-icons/bi";
import { LoadingOutlined } from "@ant-design/icons";
import { FILE_UPLOAD } from "../../utils/apiCalls";

const ImagePicker = (props: any) => {
  const [loading, setLoading] = useState<any>(false);
  const [extemsion, setExtemsion] = useState<any>(false);

  const handleDrop = (acceptedFiles: any) => {
    try {
      setLoading(true);
      props?.onLoading(true);
      const maxFileSize = 3 * 1024 * 1024;
      var myFile = acceptedFiles[0];
      if (myFile.size > maxFileSize) {
        setLoading(false);
        props?.onLoading(false);
        message.error("File size exceeded the 3MB limit.");
      } else {
        let name = moment(new Date()).unix();
        const fileExtension = myFile.name.split(".").pop();
        const myNewFile = new File([myFile], name + "G." + fileExtension, {
          type: myFile.type,
        });
        if (props.formats.includes(myNewFile.type)) {
          setExtemsion(myNewFile.type);
          const url = URL.createObjectURL(myNewFile);
          let obj = {
            file: myNewFile,
            url: url,
          };
          uploadFile(obj);
        } else {
          setLoading(false);
          props?.onLoading(false);
          message.error("File format not accepted");
        }
      }
    } catch (err) {
      setLoading(false);
      props?.onLoading(false);
      console.log("err", err);
    }
  };

  const uploadFile = async (objs: any) => {
    try {
      message.open({
        type: "loading",
        content: "Upload in progress..",
        duration: 2,
      });
      let fileUploaded: any = await FILE_UPLOAD(objs?.file);
      let obj = {
        file: objs?.file,
        url: fileUploaded?.data,
      };
      props?.onChange(obj);
      setLoading(false);
      props?.onLoading(false);
    } catch (err) {
      setLoading(false);
      props?.onLoading(false);
      message.error("File upload Failed");
    }
  };

  return (
    <Tooltip
      // color={"#1b6ad5"}
      title={
        props?.tooltip
          ? "Please upload a high-resolution headshot photo with a white background, showing your face clearly."
          : ""
      }
    >
      <div>
        <Dropzone onDrop={handleDrop}>
          {({ getRootProps, getInputProps }: any) => (
            <section>
              <div
                {...getRootProps({
                  className: "ImagePicker-Box",
                })}
              >
                <input {...getInputProps()} />
                <div>
                  {loading ? (
                    <Spin indicator={<LoadingOutlined spin />} />
                  ) : props?.fileURL ? (
                    <div>
                      <IoCheckmarkCircleOutline size={20} color="green" />
                      &nbsp; Change {props?.label}
                    </div>
                  ) : (
                    props?.label
                  )}
                </div>
                <div style={{ flex: 1 }} />
                <div>
                  {props?.fileURL ? (
                    extemsion === "application/pdf" ? (
                      <BiSolidFilePdf size={30} color="blue" />
                    ) : (
                      <img
                        src={props?.fileURL}
                        style={{ width: 20, height: 20 }}
                        alt="File"
                      />
                    )
                  ) : (
                    <RiImageAddLine size={20} color="#000" />
                  )}
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    </Tooltip>
  );
};

export default ImagePicker;
