const ThemeObj = {
  token: {
    colorPrimary: "#002646",
    fontFamily: "IsidoraSans-Medium",
    borderRadius: 0,
  },
  Input: {
    colorPrimary: "#fff",
    algorithm: true, // Enable algorithm
  },
};

export default ThemeObj;
