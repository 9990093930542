import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import { Container } from "react-bootstrap";
import Logo from "../../assets/images/adfw-logo.svg";
const Header = () => {
  const [lastScrollY, setLastScrollY] = useState<any>(0);

  const handleNavigation = useCallback(
    (e: any) => {
      const window = e.currentTarget;
      if (lastScrollY > window.scrollY) {
        setLastScrollY(false);
      } else if (lastScrollY + 5 < window.scrollY) {
        setLastScrollY(true);
      }
    },
    [lastScrollY]
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleNavigation);
      return () => {
        window.removeEventListener("scroll", handleNavigation);
      };
    }
  }, [handleNavigation, lastScrollY]);

  return (
    <div className={`header ${lastScrollY ? "scrolled" : null}`}>
      <Container>
        <div className={`header-box ${lastScrollY ? "scrolled" : null}`}>
          <img
            src={Logo}
            alt="Logo"
            className={`header-logo ${lastScrollY ? "scrolled" : null}`}
          />
        </div>
      </Container>
    </div>
  );
};

export default Header;
